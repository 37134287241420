import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import sanityClient from '../../../client'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
    return builder.image(source)
}

export default function Event() {
    const [singleEvent, setsingleEvent] = useState(null)
    const { slug } = useParams()

    useEffect(() => {
        sanityClient
            .fetch(
                `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            releaseDate,
            time,
            place,
            link,
            description,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageGallery1{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageGallery2{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageGallery3{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageLogo1{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageLogo2{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageLogo3{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageLogo4{
                asset->{
                    _id,
                    url
                },
                alt
            },

        }`
            )
            .then((data) => setsingleEvent(data[0]))
            .catch(console.error)
    }, [slug])

    if (!singleEvent) return <div>Loading...</div>

    return (
        <div className='singleEvent'>
            <img
                id='headerImg'
                src={singleEvent.mainImage.asset.url}
                alt={singleEvent.title}
            />

            <header className='flexSpace'>
                <div className='mainContent flexCenter'>
                    <h1>{singleEvent.title}</h1>
                    <h2>About this event</h2>
                </div>
                <p>{singleEvent.description}</p>
                <div className='box'>
                    <h2>Event Details</h2>
                    <div className='details'>
                        <div className='date flexSpace'>
                            <div className='top flexSpace'>
                                <img src='/images/date.png' alt='' />
                                <h3>Date</h3>
                            </div>
                            <div className='info'>
                                {singleEvent.releaseDate}
                            </div>
                        </div>
                        <div className='time flexSpace'>
                            <div className='top flexSpace'>
                                <img src='/images/time.png' alt='' />
                                <h3>Time</h3>
                            </div>
                            <div className='info'>{singleEvent.time}</div>
                        </div>
                        <div className='place flexSpace'>
                            <div className='top flexSpace'>
                                <img src='/images/place.png' alt='' />
                                <h3>Place</h3>
                            </div>
                            <div className='info'>{singleEvent.place}</div>
                        </div>
                    </div>
                    <a
                        className='subscribeBtn button flexCenter'
                        href={singleEvent.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Subscribe
                    </a>
                </div>
            </header>

            <section className='gallery flexSpace'>
                <h1>Gallery</h1>
                <div className='imgContainer flexSpace'>
                    <img src={singleEvent.imageGallery1.asset.url} alt='' />
                    <img src={singleEvent.imageGallery2.asset.url} alt='' />
                    <img src={singleEvent.imageGallery3.asset.url} alt='' />
                </div>
            </section>

            <section className='sponsors flexSpace'>
                <h1>Sponsors</h1>
                <div className='imgContainer flexSpace'>
                    <img src={singleEvent.imageLogo1.asset.url} alt='' />
                    <img src={singleEvent.imageLogo2.asset.url} alt='' />
                    <img src={singleEvent.imageLogo3.asset.url} alt='' />
                    <img src={singleEvent.imageLogo4.asset.url} alt='' />
                </div>
            </section>
        </div>
    )
}
