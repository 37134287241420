import React from 'react'

export default function Thanks() {
    return (
        <div className='thanksPage flexCenter'>
            <h1>Thank You</h1>
            <img src='/images/thanks.png' alt='Thanks' />
        </div>
    )
}
