import React from 'react'

export default function AboutUs() {
    return (
        <section className='aboutUs'>
            <div className='header flexCenter'>
                <h1>About Us</h1>
                <div className='headerContent flexSpace'>
                    <img src='images/man.png' alt='' />
                    <p>
                        IEEE is the world’s largest technical professional
                        organization dedicated to advancing technology for the
                        benefit of humanity.
                        <br />
                        IEEE and its members inspire a global community to
                        innovate for a better tomorrow through its more than
                        400,000 members in more than 160 countries, and its
                        highly cited publications, conferences, technology
                        standards, and professional and educational activities.
                        IEEE is the trusted “voice” for engineering, computing,
                        and technology information around the globe.
                    </p>
                </div>
            </div>
            <div className='owner flexSpace'>
                <img src='images/doctor.png' alt='' />
                <div className='mail'>
                    <a href='#s' className='flexCenter'>
                        <img src='images/email.png' alt='email' />
                    </a>
                </div>
                <div className='name'>
                    <h2>Dr. Ghassan Alnwaimi</h2>
                </div>
                <div className='position'>Supervisor</div>
            </div>

            <div className='team flexCenter'>
                <h1>Our Team</h1>
                <div className='teamInfo flexSpace'>
                    <div className='wael flexSpace'>
                        <img src='images/wael.png' alt='' />
                        <a
                            href='https://www.linkedin.com/in/wsa9'
                            className='flexCenter'
                        >
                            <img src='images/linkedin.png' alt='linkedin' />
                        </a>
                        <div className='name'>
                            <h2>Wael Alqarni</h2>
                        </div>
                        <div className='position'>President</div>
                    </div>
                    <div className='abdulziz flexSpace'>
                        <img src='images/abdulaziz.png' alt='' />

                        <a
                            href='https://www.linkedin.com/mwlite/in/abdulaziz-ebrahim-037a7b1ab'
                            className='flexCenter'
                        >
                            <img src='images/linkedin.png' alt='linkedin' />
                        </a>
                        <div className='name'>
                            <h2>Abdulaziz Hamid</h2>
                        </div>
                        <div className='position'>vice president</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
