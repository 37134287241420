import React, { useState, useEffect } from 'react'
import sanityClient from '../../client.js'

function rights() {
    document.querySelector('#copyright-year').innerText =
        new Date().getFullYear()
}

export default function Home() {
    const [nextEventData, setNextEvent] = useState(null)

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == 'nextEvent']{
                title,
                slug,
                releaseDate,
                link1,
                link2,
                description,
                mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
            }`
            )
            .then((data) => setNextEvent(data))
            .catch(console.error)
    }, [])

    return (
        <div className='homeBody'>
            <section className='landing flexCenter'>
                <h1>Welcome To The IEEE KAU SB Official Website</h1>
                <h6>Advancing Technology for Humanity</h6>
                <a href='#next'>Next Event</a>

                <img className='core' src='/images/core.png' alt='Core' />
                <img className='wave1' src='/images/wave.png' alt='Wave 1' />
            </section>

            <section className='news flexSpace'>
                {/* Next Event */}
                <div id='next' className='nextEvent flexSpace'>
                    <img className='circuit' src='/images/circuit.png' alt='' />
                    <h1>Next Event</h1>
                    {nextEventData &&
                        nextEventData.map((nextEvent, index) => (
                            <div className='box flexEnd' key={index}>
                                <img
                                    src={nextEvent.mainImage.asset.url}
                                    alt={nextEvent.mainImage.alt}
                                />
                                <div className='boxDetails flexSpace'>
                                    <div className='textContent flexSpace'>
                                        <h6>{nextEvent.title}</h6>
                                        <p>{nextEvent.description}</p>
                                    </div>
                                    <div className='buttons flexSpace'>
                                        <div className='date'>
                                            {nextEvent.releaseDate}
                                        </div>
                                        <a
                                            className='readMoreBtn button flexCenter'
                                            href={nextEvent.link1}
                                        >
                                            Read More
                                        </a>
                                        <a
                                            className='subscribeBtn button flexCenter'
                                            href={nextEvent.link2}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Subscribe
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                <img className='wave2' src='/images/wave-2.png' alt='' />
            </section>

            <section className='contact flexCenter'>
                <img className='contact1' src='/images/contact1.png' alt='' />
                <img className='contact2' src='/images/contact2.png' alt='' />
                <div className='box flexSpace'>
                    <div className='leftSide'>
                        <img
                            src='/images/IEEE_Logo_Blue.png'
                            alt='Logo'
                            className='logo'
                        />
                        <h3>TELL US WHAT YOU ARE THINKING ABOUT 😄 </h3>
                        <p>
                            We respect whatever you want to say to IEEE KAU SB
                            and your amazing opinion make IEEE KAU SB more
                            better ✨
                        </p>
                    </div>
                    <div className='rightSide flexSpace'>
                        <h3>Contact Us</h3>
                        <form
                            action='https://formsubmit.co/ieeekau1@gmail.com'
                            method='POST'
                        >
                            <input
                                type='hidden'
                                name='_subject'
                                value='New submission!'
                            />
                            <div className='smallBox'>
                                <label>Name / Company Name</label>
                                <br />
                                <input
                                    className='inputS'
                                    type='text'
                                    name='text'
                                    placeholder='your name'
                                    required
                                />
                            </div>

                            <div id='contactUs' className='smallBox'>
                                <label>E-mail</label>
                                <br />
                                <input
                                    className='inputS'
                                    type='email'
                                    name='email'
                                    placeholder='Email Address'
                                />
                            </div>

                            <div className='smallBox'>
                                <label>Subject</label>
                                <br />
                                <input
                                    className='inputS'
                                    type='text'
                                    name='text'
                                    placeholder='Subject'
                                    required
                                />
                            </div>

                            <input
                                type='hidden'
                                name='_next'
                                value='https://ieeekau.com/thanks'
                            />

                            <div className='smallBox'>
                                <label>How may we help you?</label>
                                <br />
                                <textarea
                                    name='message'
                                    placeholder='your message...'
                                ></textarea>
                            </div>

                            <button type='submit' className='button flexCenter'>
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </section>
            <footer className='flexCenter'>
                <p>
                    All Rights Reserved IEEE KAU &copy;
                    <span id='copyright-year'></span>
                </p>
            </footer>
        </div>
    )
}
