import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './css/style.css'

// ? Importing components
import Home from './components/Home/Home'
import SingleEvent from './components/Events/Event/Event'
import Events from './components/Events/Events'

import AboutUs from './components/AboutUs/AboutUs'
import JoinUs from './components/JoinUs/JoinUs'
import NavBar from './components/NavBar/NavBar'
import Thanks from './components/Thanks/Thanks'

function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <Switch>
                <Route component={Home} path='/' exact />
                <Route component={SingleEvent} path='/events/:slug' />
                <Route component={Events} path='/events' />

                <Route component={AboutUs} path='/about_us' />
                <Route component={JoinUs} path='/join_us' />
                <Route component={Thanks} path='/thanks' />
            </Switch>
        </BrowserRouter>
    )
}

export default App
