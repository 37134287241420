import React from 'react'

export default function JoinUs() {
    return (
        <section className='joinUs flexSpace'>
            <div className='cards flexSpace'>
                <div className='card one flexSpace'>
                    <img className='imgSize' src='images/InfoIEEE.png' alt='' />
                    <h1>Info About IEEE</h1>
                    <a href='https://drive.google.com/file/d/1txZmHraBkM5VjKMZMUG5M91JKyb6ueyZ/view?usp=sharing'>
                        Find Out More
                    </a>
                </div>
                <div className='card two flexSpace'>
                    <img src='images/benefits.png' alt='' />
                    <h1>Benefits</h1>
                    <a href='https://drive.google.com/file/d/1ECO8qZFDZi2VDKX8mHINB1AAsuC_Tfjb/view?usp=sharing'>
                        Find Out More
                    </a>
                </div>
            </div>
            <a className='joinBtn' href='https://forms.gle/mzYqkW4CbhJ4Je6x5'>
                Be a member in IEEE KAU SB
            </a>
        </section>
    )
}
