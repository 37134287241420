import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import sanityClient from '../../client.js'

export default function Events() {
    const [eventData, setEvent] = useState(null)

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == 'events']{
            title,
            slug,
            releaseDate,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }

        }`
            )
            .then((data) => setEvent(data))
            .catch(console.error)
    }, [])

    return (
        <article>
            <div className='container'>
                {eventData &&
                    eventData.map((events, index) => (
                        <span key={index}>
                            <img
                                src={events.mainImage.asset.url}
                                alt={events.mainImage.alt}
                            />
                            <div className='content flexSpace'>
                                <h5>{events.releaseDate}</h5>
                                <h2>{events.title}</h2>
                                <div className='line'>
                                    ____________________________________
                                </div>
                                <Link
                                    to={'/events/' + events.slug.current}
                                    key={events.slug.current}
                                    className='learnMore'
                                >
                                    Learn More
                                </Link>
                            </div>
                        </span>
                    ))}
            </div>
        </article>
    )
}
