import React from 'react'
import { NavLink } from 'react-router-dom'

const body = document.body
let lastScroll = 0

window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset

    if (currentScroll <= 0) {
        body.classList.remove('scroll-up')
    }

    if (currentScroll > lastScroll && !body.classList.contains('scroll-down')) {
        body.classList.remove('scroll-up')
        body.classList.add('scroll-down')
    }

    if (currentScroll < lastScroll && body.classList.contains('scroll-down')) {
        body.classList.remove('scroll-down')
        body.classList.add('scroll-up')
    }

    lastScroll = currentScroll
})

export default function NavBar() {
    return (
        <nav className='flexSpace'>
            <div className='logo'>
                <NavLink to='/'>
                    <img src='/images/IEEE-LOGO-White.png' alt='IEEE Logo' />
                </NavLink>
            </div>
            <ul className='flexSpace nav-links'>
                <NavLink className='li' to='/' exact>
                    Home
                </NavLink>
                <NavLink className='li' to='events' exact>
                    Events
                </NavLink>
                <NavLink className='li' to='about_us' exact>
                    About Us
                </NavLink>

                <NavLink className='li button' to='join_us'>
                    Join Us
                </NavLink>
            </ul>
        </nav>
    )
}
